<template>
  <b-card>
    <form-component @onSubmit="onSubmit" :category="category" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/blog/categories/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/blog/categories';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      category: {
        id: '',
        county_id: '',
        about_us_id: false,
        value: '',
        surname: '',
        phone: '',
        email: '',
        postal_code: '',
        city: '',
      },
    };
  },

  async mounted() {
    await this.fetchCategory();
  },

  methods: {
    async fetchCategory(params = {}) {
      try {
        const { data } = await show(this.$route.params.id, params);
        this.category = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    onSubmit(form) {
      update(this.category.id, form)
        .then(() => {
          this.showSuccessNotification('Dane zostały zapisane', 'Kategoria została edytowana.');
          this.$router.push({ name: 'blog-categories-index' });
        })
        .catch((error) => {
          const message =
            error.response.data.message ?? 'Wystąpił problem z edycją kategorii. Skontaktuj się ze swoim developerem.';

          this.showErrorNotification('Problem z zapisaniem danych', message);
        });
    },
  },
};
</script>
